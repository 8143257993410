import React from "react"
import Layout from "../../components/layout"

export default function TeamGirlsU11() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Girls Under 11's</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/girls-u11.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
                <p>This will be our second season with the girls who have all developed through our Wildcats centre and shown how brilliant the centre was for them. We play in the West Riding Girls League against local teams from the Leeds area and also play in a bi weekly Gala at A1 Football Factory in Pontefract organised by WRGL. The aim with the girls will be to develop them into playing an attractive style of football which makes them all comfortable on the ball and give them an understanding of all positions they will play as they progress.</p>
                <p>Every team has Managers, Coaches or Assistants who are either FA or UEFA qualified. In addition all staff are CRC checked with at least one First Aid qualified member present at all times.</p>
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 6, DoB 01/09/2011 - 31/08/2012</dd>
                <dt>Manager(s)</dt>
                <dd>Jodi Walsh</dd>
                <dt>Training</dt>
                <dd>Wednesday 17:30 – 18:30 in the winter at Astro Turf and summer on Bedquilts playing field</dd>
                <dt>League</dt>
                <dd>Westriding Girls League</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:jodi.walsh@hotmail.com" target="_blank" rel="noopener noreferrer">jodi.walsh@hotmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-kipmcgrath.jpg" />
                <div className="card-body">
                    <p className="card-text">Kip McGrath provides live and interactive lessons online or in centre, tailored to your child’s needs.</p>
                    <div className="btn-group">
                    <a href="https://www.kipmcgrath.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

